import figurt from "./images/figurt-v0-rotated-resized-fat.png";

const Home = ({setIsPopupOpen, popupId, setPopupId}) => {
    return (
        <div className='Home'>
            <div className="Title">
                <svg viewBox="0 0 1000 150">
                    <defs>
                        <path id="curvedPath"
                            d="M 150 150 Q 500 30 850 150"/>
                    </defs>
                    <text>
                        <textPath xlinkHref="#curvedPath" startOffset="50%">das <tspan fill="#B88428">YOGA</tspan>mehr</textPath>
                    </text>
                </svg>
                <p id="subtitle">Körper-Coaching für Seele & Darm</p>
            </div>
            <div className="ImageContainer">
                <div>
                    <p>Traumasensibel</p>
                    <p>Behutsam</p>
                    <p>Mitfühlend</p>
                </div>
                <img src={figurt} alt="figurt"/>
                <div>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(0); setIsPopupOpen(true)}}><span>Meditation</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(1); setIsPopupOpen(true)}}><span>Trauma</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(2); setIsPopupOpen(true)}}><span>Darm</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(3); setIsPopupOpen(true)}}><span>Stress, Leben & Co</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(4); setIsPopupOpen(true)}}><span>Ernährung</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(5); setIsPopupOpen(true)}}><span>Atmen</span></a>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => {setPopupId(6); setIsPopupOpen(true)}}><span>YOGA</span></a>
                </div>
            </div>
            <div className="Endtle">
                <p>Deine ganzheitliche <span className="ockerAccent">YOGA</span>Praxis</p>
                <p><span className="ockerAccent">BAUCH</span>geflüster</p>
                <p>derz. Probanden <span className="ockerAccent">gesucht</span> für Einzeleinheiten für meine integrale <span className="ockerAccent">Yoga-Therapie-Ausbildung</span></p>
            </div>
        </div>
    )
}

export default Home;