import meimg from "./images/meimg.jpeg";

const About = () => {
    return (
        <div className="About">
            <div className="Title">
                <p>Über <span className="ockerAccent">mich</span></p>
            </div>
            <div className="Content">
                <div>
                    <p>Hallo, ich bin <span className="ockerAccent">Nadin</span></p>
                    <img src={meimg} alt="Bild von mir"/>
                    <p><span className="ockerAccent">Ich</span> SELBST bin:</p>
                    <p>Mensch, Frau, Mama, Freundin, Erfahrende, lebendig, begeisterungsfähig, neugierig, sanftmütig, emphatisch und noch Werte lebend und Augenkontakt vorziehend</p>
                </div>
                <ul>
                    <p><span className="ockerAccent">Ich</span> SELBST bin:</p>
                    <p>Mensch, Frau, Mama, Freundin, Erfahrende, lebendig, begeisterungsfähig, neugierig, sanftmütig, emphatisch und noch Werte lebend und Augenkontakt vorziehend</p>
                    <p>und <span className="ockerAccent">beruflich</span>:</p>
                    <li>Pädagogin mit <span className="ockerAccent">&#9829;</span></li>
                    <li>dipl. YOGAlehrende</li>
                    <li>zert. Trauma-Yoga-Coach (traumasensibles Yoga TSY)</li>
                    <li>Traumafachberatung (i. A.)</li>
                    <li>integrale Yogatherapie bei psychischen und stressbedingten Erkrankungen (i. A.)</li>
                    <li>Achtsamkeits & Meditationslehrende</li>
                    <li>Ernährungstrainerin der Psychoneuroimmunologie</li>
                    <li>div. Darm Intaktkurse über "artgerecht"</li>
                    <li>Darmtherapeutin</li>
                    <li>ausgebildete Expertin für Darmgesundheit & Darmentspannung (Proges Österreich)</li>
                    <li>zert. Fachberatung für Darmgesundheit der Öprom / Allergosan</li>
                    <li>YIN YOGA (i. A.)</li>
                    <li>MBSR Anwenderin</li>
                    <li>TRE - Anwenderin (neurogenes Zittern)</li>
                </ul>
            </div>
        </div>
    )
}

export default About;