import { useState } from 'react';
import './App.css';
import NavBar from './NavBar';
import Home from './Home';
import Courses from './Courses';
import About from './About';
import Contact from './Contact';
import Popup from './Popup';
import E404 from './E404';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
    const [popupId, setPopupId] = useState(undefined);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <div className='App'>
            <Router>
                <div className='Background'></div>
                <NavBar/>
                <div className="Spacer"></div>
                <Routes>
                    <Route exact path='/' element={
                        <Home setIsPopupOpen={setIsPopupOpen} popupId={popupId} setPopupId={setPopupId}/>
                    }></Route>
                    <Route exact path='/courses' element={
                        <Courses/>
                    }></Route>
                    <Route exact path='/about' element={
                        <About/>
                    }></Route>
                    <Route exact path='/contact' element={
                        <Contact/>
                    }></Route>
                    <Route exact path='*' element={
                        <E404/>
                    }></Route>
                </Routes>
                <Popup isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} popupId={popupId}/>
            </Router>
        </div>
    );
}

export default App;