import bgimg from './images/background.jpeg';

const Contact = () => {
    return (
        <div className="Contact">
            <div className="Title">
                <p>Kontakt</p>
                <p><span className="ockerAccent">Ich</span> freu mich von <span className="ockerAccent">Dir</span> zu hören!</p>
            </div>
            <p>Nadin Praster</p>
            <div className="Content">
                <div className="ContactEntry">
                    <i className="fi fi-rr-phone-call"></i>
                    <div>
                        <p>gerne auch mit <span className="ockerAccent">Anruf</span> oder <span className="ockerAccent">SMS</span></p>
                        <p><a href="tel:+43 680 5565946"><span>+43 680 5565946</span></a></p>
                    </div>
                </div>
                <div className="ContactEntry">
                    <i className="fi fi-rr-envelope"></i>
                    <div>
                        <p>oder per <span className="ockerAccent">Mail</span></p>
                        <p><a href="mailto:nadin.sachs@gmx.net"><span>nadin.sachs@gmx.net</span></a></p>
                    </div>
                </div>
            </div>
            <img src={bgimg} alt="bild"/>
            <p>Website gemacht von <a href="https://github.com/critiqalfish" target="_blank" rel="noreferrer"><span>Linus</span></a></p>
        </div>
    )
}

export default Contact;