import { useEffect } from "react";

const Popup = ({isPopupOpen, setIsPopupOpen, popupId}) => {
    const texts = {
        0: ["Meditation", ["Buddha wurde einmal gefragt: \"Was hast du durch Meditation gewonnen?\".", <br/>, "Er antwortete: \"Nichts! Aber lass mir dir sagen, was ich danach verloren habe:", <br/>, <br/>, "WUT, ZUKUNFTSANGST, DEPRESSIONEN, UNSICHERHEIT, ANGST vor dem ALTER und vor dem TOD.\n"]],
        1: ["Trauma", ["\"Deine Wunden werden durch die Heilung zu einem kostbaren Besitz, zu kostbaren Perlen\"", <br/>, "- (Hildegard von Bingen)"]],
        2: ["Darm", ["\"Der Darm ist die Wurzel der Pflanze Mensch.\"", <br/>, "- (Franz Xaver Mayr)"]],
        3: ["Stress, Leben & Co", ["Erfahre sensible Begleitung hierdurch:", <br/>, <br/>, "Depressionen, Schmerzen, Burnout, Schlafdefizite, Erschöpfung, Dissoziation, PTBS, Angst u. Paniken, Süchten"]],
        4: ["Ernährung", ["\"Der Weg zur Gesundheit führt durch die Küche nicht durch die Apotheke.\"", <br/>, "- (Sebastion Kneipp)"]],
        5: ["Atmen", ["\"Wenn du aufgebraucht bist, tue oder sage erstmal nichts. Atme nur ein und dann aus, bis du ruhig genug bist\"", <br/>, "- (Thich Nhat Hanh)", <br/>, <br/>, "Dein Atem = Dein Anker"]],
        6: ["YOGA", ["Wahrnehmungsorientiertes therapeutisches, nicht rein leistungsorientiert oder sportliches Yoga, darfst du hier erfahren.", <br/>, "Deinen Körper sprechen \"spüren\", Deine Signale frühzeitig \"hören\". Somit in heilsame Selbstregulationsarbeit mit dir gehen zu können.", <br/>, "Im Geist kann das \"Drama\" zur Ruhe kommen. Und mit deinem Körper \"bewegst\" du dich immer im \"Hier und Jetzt\"."]]
    };

    useEffect(() => {
        if (isPopupOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
      }, [isPopupOpen]);

    if (!isPopupOpen) return null;

    return (
        <div className="Popup">
            <div className="Content">
                <div>
                    <p>{texts[popupId][0]}</p>
                    <button onClick={() => {setIsPopupOpen(false)}}><span>+</span></button>
                </div>
                <p>{texts[popupId][1]}</p>
            </div>
        </div>
    )
}

export default Popup;