import { Link } from "react-router-dom";

const Courses = () => {
    return (
        <div className="Courses">
            <div className="Title">
                <p>Kurse & Angebote</p>
            </div>
            <div className="Content">
                <div>
                    <p>Meine <span className="ockerAccent">laufenden</span> Kurse für <span className="ockerAccent">Dich</span>:</p>
                    <p>Einstieg <span className="ockerAccent">jederzeit</span> möglich - für <span className="ockerAccent">Jedermann</span> und <span className="ockerAccent">Jederfrau</span></p>
                    <div className="CourseBox">
                        <p>AchtsamkeitsYoga</p>
                        <div>
                            <p>Atmung - Körper - Gedankenwelt</p>
                            <p>Dich immer wieder ganz im Hier & Jetzt erfahren. Auszeit vom SEIN im Draußen. Ganz-SEIN im Moment. Fühl dich wohl, sei neugierig.</p>
                            <p>Laufend Montags im Lunaris in Villach (St. Martiner Str. 38) um 18:45 Uhr (Einlass um 18:30 Uhr mit Tee).<br/>Dauer 75 min.<br/>Kosten Schnuppereinheit 10€<br/>Einzelbuchung 13€ oder 5er Block für 50€.</p>
                            <p>Um Voranmeldung am selben Tag (bis Mittag) wird gebeten.</p>
                        </div>
                    </div>
                    <div className="CourseBox">
                        <p>Stressmanagement & Entspannungstraining</p>
                        <div>
                            <p>"In der Ruhe liegt die Kraft". Wie kann ich die Herausforderungen meines Lebens für mich schadlos "durchsegeln"?</p>
                            <p>Schritt für Schritt Begleitung bis zur eigenen Wohlfühlsituation ganz für dich.<br/>Inklusive Entspannungsreise, leichtem Achtsamkeitsyoga und Atemübungen usw.</p>
                            <p>Gratis in der Frauenberatung Villach, immer Freitags um 17:30 Uhr.<br/>Bitte kontaktiere mich für Weiteres.</p>
                        </div>
                    </div>
                    <div className="CourseBox">
                        <p>YinYoga</p>
                        <div>
                            <p>Ein meditativ yogischer Stil. Der Gegenpol zum aktiven Yang im Yoga.<br/>Das Yin; das Weiche, das Ruhige, empfangend einfach nur SEIN.</p>
                            <p>Auf körperlicher Ebene wird unter anderem die Ausdauer, die Beweglichkeit neu wahrgenommen.<br/>Faszien, Bänder, Sehnen gedehnt... geistig ist es in erster Weise eine Wohltat und Entspannung. Fühl dich fließend und frei, ruhend und immer wieder etwas Neu.</p>
                            <p>Neugierig? Dann erfrage die Kurszeiten gerne bei mir.</p>
                        </div>
                    </div>
                    <div className="CourseBox">
                        <p>Meditation</p>
                        <div>
                            <p>Speziell für Anfänger bei seelischen Krisen.</p>
                            <p>ins Leben integrieren, den Geist fokussieren, Techniken ausprobieren und den Körper durch Übungen aufs "Sitzen" vorbereiten.</p>
                            <p>Du erfährst hier die wohltuende Kraft des heilsamen Herantastens an kleine einfache Atemübungen zur Vorbereitung.<br/>Lädst deinen Körper zu einfachen Dehnübungen ein, um im klassischen "Yogisitz" oder "Lotussitz", dich langsam immer länger halten zu können.</p>
                            <p>Du übst behutsam erstmal mit z.B. offenen Augen und einer bequemen Haltung deiner eigenen Gedankenwelt geduldig und wertfrei zu zuschauen und zu zuhören. Erst dann, von dort aus, kann langsam die Stille erfahren werden. Wir gehen spielerisch heran. Von dort entfaltet sich dann die heilsame Kraft der Meditation.</p>
                            <p>Der Kurs beinhaltet 30 Min. körperliche Vorbereitung mit Inhalten vom Yoga, 10 Min. Atemerfahrungen und 20 Min. Meditation "üben und anwenden" (verschiedene Techniken z.B. Gehmeditation, Achtsamkeitsmed., Mitgefühlsmed., Vipassanamed., usw.).</p>
                            <p>Laufende Präsenz- und Online-Angebote bitte bei mir anfragen.</p>
                        </div>
                    </div>
                    <div className="CourseBox">
                        <p>PaarYoga - eine Besonderheit</p>
                        <div>
                            <p>Im Alltag gemeinsam den Weg des Herzens gehen.</p>
                            <p>Welches Paar wünscht sich nicht, gemeinsam zu wachsen, einander in der Tiefe zu verstehen und im Miteinander das volle Potenzial der Liebe zu entfalten oder wieder neu aufflammen zu lassen.<br/>Räume der Begegnung und des Kontakts lassen jenseits von Worten wieder "Oasen der Liebe" und "Nähe und Zärtlichkeit" entstehen</p>
                            <p>Auch für männliche und weibliche "Nicht-Yogis" sehr gut geeignet. Eine gute Gelegenheit deinen Partner an diesem Tag noch zu einem schönen Abend einzuladen.</p>
                            <p>Findet statt ab 3 Paaranmeldungen.</p>
                            <p>Traut euch!</p>
                        </div>
                    </div>
                    <p>Anmeldung per Nachricht bitte immer mindestens 24h vor dem Kurs.<br/>Bei Fragen <Link to="/contact"><span>kontaktiere mich</span> gerne</Link>.</p>
                </div>
                <div>
                    <p>Meine <span className="ockerAccent">individuellen</span> Angebote für <span className="ockerAccent">Dich</span>:</p>
                    <p>auf <span className="ockerAccent">Anfrage</span></p>
                    <div className="CourseBox">
                        <p>Therapeutisches Yogacoaching</p>
                        <div>
                            <p>Wahrnehmungsorientiert - nicht Leistungsorientiert - traumasensibles Yoga Coaching in Einzelarbeit</p>
                            <p>Ich nehme mir in der Körperarbeit Zeit für deine Themen, welche sein können:<br/>Depression, Burnout, Stressgefühle, Darmproblematiken, Schlafsorgen, Grübelein, Ängste & Paniken, Süchte, Lebensdramen & Stagnation, Dissoziationen, Schmerzen, Trauer, Unfälle, Allergien, Unverträglichkeiten... und TRAUMA.</p>
                            <p>Du bist bereits in psychologischer Therapie und besprichst dort deine Themen? Gut so. Hier in meiner Praxis nehmen wir den Körper dazu. Wir arbeiten ergänzend zusammen.<br/>Du bist in der Warteliste für einen psychotherapeutischen Platz oder überlegst noch? Dann fühle dich erstmal bei mir willkommen. Wir fangen einfach schon mal an für ein besseres Gefühl zu sorgen während du wartest. Denn es ist deine Lebenszeit.</p>
                            <p>Kosten erste Schnuppereinheit zu 60 Min. für 25€.<br/>75 Min bei Einzelbuchung für 50€.<br/>5er Set zu einer festen Zeit, 30€ je Sitzung.<br/>10er Set zu einer festen Zeit, 25€ je Sitzung.<br/>Bei Buchung eines 10er Blocks in Einzelarbeit, Möglichkeit zur wöchentlichen Teilnahme an einem meiner Gruppenkurse gratis zusätzlich.<br/>Programmerarbeitung fürs Üben zuhause und 1x im Monat Termin - 60€.</p>
                            <p>Auch gerne Onlinearbeit möglich. Frage einfach nach.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses;