import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return (
        <div className='NavBar'>
            <ul>
                <li className='home'><NavLink to='/'>Home</NavLink></li>
                <li className='courses'><NavLink to='/courses'>Kurse</NavLink></li>
                <li className='about'><NavLink to='/about'>Über mich</NavLink></li>
                <li className='contact'><NavLink to='/contact'>Kontakt</NavLink></li>
            </ul>
        </div>
    )
}

export default NavBar;